import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import { window } from 'browser-monads';

const Post = ({ data: { prismicServices } }) => {
	const { data } = prismicServices;
	const url = window.location.pathname;
	const langKey = url.substring(1, 3);
	return (
		<Layout bodyClass="page-teams">
			<SEO title={data.service_name.text} />
			<div className="container pt-4 pt-md-10 mb-10">
				<div className="row justify-content-start">
					<div className="col-12 col-md-8">
						<div className="service service-single">
							<h1 className="title">{data.service_name.text}</h1>
							<div
								className="content"
								dangerouslySetInnerHTML={{ __html: data.content.html }}
							/>
						</div>
					</div>
					<div className="container pt-2">
						<a className="button" href={`/${langKey}/contact/`}>
							Kontakta oss
						</a>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Post;

export const pageQuery = graphql`
	query PostBySlug($uid: String!) {
		prismicServices(uid: { eq: $uid }) {
			id
			data {
				content {
					html
				}
				service_name {
					text
				}
			}
		}
	}
`;
